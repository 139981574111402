import Script from 'next/script';

export const CF = (props: { CFToken: string }): JSX.Element | null => {
  return (
    <Script
      defer
      src="https://static.cloudflareinsights.com/beacon.min.js"
      data-cf-beacon={`{"token": "${props.CFToken}"}`}
      strategy="afterInteractive"
    />
  );
};
