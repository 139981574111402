// https://www.npmjs.com/package/next-seo
// eslint-disable-next-line import/no-anonymous-default-export
const PlausibleConfig = {
	domain: '2aes-group.it',
	selfHosted: true,
	customDomain: 'https://pla.2aesgroup.it',
	trackOutboundLinks: true
}

export default PlausibleConfig
