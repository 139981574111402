import '../styles/globals.scss'
import '@fontsource/poppins'
import 'animate.css'

import { CF, CookieConsent2AeS, GooglePageview, PixelPageview } from '@websites-monorepo/analytics'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import PlausibleProvider from 'next-plausible'
import { useEffect } from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'

import AppConfig from '../config/AppConfig'
import PlausibleConfig from '../config/next-plausible'
// import CF from '@/lib/CF'
// import * as fbq from '@/lib/FPixel'

// import * as gtag from '@/lib/Gtag'
// import CookieConsent2AeS from '@/shared/CookieConsent2AeS'
// import Meta from '@/shared/Meta'

// export function reportWebVitals({ id, name, label, value }) {
//   // Use `window.gtag` if you initialized Google Analytics as this example:
//   // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
//   window.gtag &&
//     window.gtag('event', name, {
//       event_category:
//         label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//       value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//       event_label: id, // id unique to current page load
//       non_interaction: true // avoids affecting bounce rate.
//     })
// }

function CustomApp({ Component, pageProps }: AppProps) {
	const router = useRouter()
	const cookieConsent = getCookieConsentValue('AcceptCookie')

	useEffect(() => {
		// eslint-disable-next-line unicorn/numeric-separators-style
		// hotjar.initialize(2935180, 6)
	}, [])

	useEffect(() => {
		if (cookieConsent === 'true') {
			PixelPageview()

			const handleRouteChange = (url: string) => {
				GooglePageview(url)
				PixelPageview()
			}
			router.events.on('routeChangeComplete', handleRouteChange)
			router.events.on('hashChangeComplete', handleRouteChange)

			return () => {
				router.events.off('routeChangeComplete', handleRouteChange)
				router.events.off('hashChangeComplete', handleRouteChange)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cookieConsent])

	return (
		<>
			{/* <Meta /> */}

			<CF CFToken={AppConfig.CFToken} />

			<CookieConsent2AeS FBPixel={AppConfig.CFToken} GoogleTagID={AppConfig.GoogleTagID} />

			<PlausibleProvider {...PlausibleConfig}>
				<main className="app">
					<Component {...pageProps} />
				</main>
			</PlausibleProvider>
		</>
	)
}

export default CustomApp
