/**
 * @summary Base di configurazione del seo json SD.
 * @description Informazioni di base del Seo meta utilizzati da next-seo e Meta json SD https://schema.org/
 * @version 1.0.0
 * @since 2022/04
 * @author Zamparelli, Trinchero
 * @copyright 2AeS Group Srl ©
 */

const _baseUrl = 'https://www.2aes-group.it/'

const AppConfig = {
	canonical: _baseUrl,
	description: 'Il tuo partner tecnologico',
	legalName: '2AeS Group s.r.l.',
	locale: 'it',
	name: '2AeS Group',
	site_name: '2aes-group.it',
	title: '2AeS Group - ecommerce e siti web torino',
	url: _baseUrl,
	keywords:
		'digitalizzazione torino,ecommerce,torino,siti-web,digital,digitale,digitalizzazione,siti web,siti web torino, siti web gratis,ecommerce',
	telephone: '+39-3517204365',
	address: {
		streetAddress: 'Corso Carlo e Nello Rosselli 119',
		addressLocality: 'Torino',
		addressRegion: 'TO',
		postalCode: '10129',
		addressCountry: 'IT'
	},
	geo: {
		latitude: '45.0541',
		longitude: '7.6515'
	},
	logo: 'https://www.2aes-group.it/logo.png',
	social: [
		'http://www.facebook.com/2aesgroup',
		'http://instagram.com/2aesgroup',
		'http://www.linkedin.com/company/2aesgroup'
	],
	FBPixel: '',
	GoogleTagID: '',
	CFToken: ''
}

export default AppConfig
