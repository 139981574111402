/* eslint-disable jsx-a11y/alt-text */

import Head from 'next/head';
import Script from 'next/script';

declare global {
  interface Window {
    fbq: (
      action: string,
      name: string,
      options?: Record<string, unknown>
    ) => void;
  }
}

export const PixelPageview = () => {
  window.fbq && window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const PixelEvent = (name: string, options = {}) => {
  window.fbq('track', name, options);
};

export const FBPixel = (props: { FacebookPixedID: string }) => (
  <>
    <Head>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${props.FacebookPixedID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </Head>

    <Script
      strategy="afterInteractive"
      id="fpx"
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${props.FacebookPixedID});
          fbq('track', 'PageView');
          `,
      }}
    />
  </>
);

export default { FBPixel, PixelEvent, PixelPageview };
