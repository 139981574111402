/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @copyright 2AeS Group Srl ©
 * Creazione: 2022/04
 * @author Zamparelli, Trinchero
 * @summary Cookie banner, plausible controllo e track accettazione rifiuto , codici analitici esterni
 * @description Aggiungere come component nell'apposita sazione i codici degli analitici esterni
 */

import Link from 'next/link';
import { usePlausible } from 'next-plausible';
import { useEffect, useState } from 'react';
import CookieConsent, {
  CookieConsentProps,
  getCookieConsentValue,
} from 'react-cookie-consent';
import { GTag } from './Gtag';
import { FBPixel } from './FPixel';

const CookieConsentConfig: CookieConsentProps = {
  location: 'bottom',
  buttonText: 'Accetta',
  cookieName: 'AcceptCookie',
  enableDeclineButton: true,
  declineButtonText: 'Rifiuta',
  disableStyles: true,
  acceptOnOverlayClick: true,
  containerClasses: 'cookie-container',
  contentClasses: 'cookie-content',
  buttonWrapperClasses: 'cookie-btn-wrapper',
  buttonClasses: 'cookie-btn-accept',
  declineButtonClasses: 'cookie-btn-decline',
  expires: 365,
};

/* https://github.com/Mastermindzh/react-cookie-consent */
export const CookieConsent2AeS = (props: {
  GoogleTagID: string;
  FBPixel: string;
}) => {
  const [analyticsConsent, updateAnalyticsConsent] = useState('denied');

  const cookieConsent = getCookieConsentValue('AcceptCookie');
  const plausible = usePlausible();

  useEffect(() => {
    if (cookieConsent === 'true') updateAnalyticsConsent('granted');
    else if (cookieConsent === 'false') updateAnalyticsConsent('denied');
  }, [cookieConsent]);

  useEffect(() => {
    analyticsConsent === 'denied'
      ? plausible('cookieDenied')
      : plausible('cookieAccepted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsConsent]);

  return (
    <>
      <CookieConsent
        {...CookieConsentConfig}
        onDecline={() => {
          updateAnalyticsConsent('denied');
        }}
        onAccept={() => {
          updateAnalyticsConsent('granted');
        }}
      >
        <details>
          <summary>
            Noi e terze parti selezionate utilizziamo cookie o tecnologie simili
            per finalità tecniche e, con il tuo consenso, anche per altre
            finalità come specificato nella{' '}
            <span className="underline">
              <Link href="/cookiePolicy" passHref>
                <a>cookie policy.</a>
              </Link>
            </span>
          </summary>
          Puoi acconsentire all’utilizzo di tali tecnologie utilizzando il
          pulsante “Accetta”. Chiudendo questa informativa, continui senza
          accettare.
        </details>
      </CookieConsent>
      {analyticsConsent === 'granted' && (
        //AGGIUNGERE CODICE ANALITICI ESTERNI IN QUESTO PUNTO PER GARANTIRNE L'ESCLUSIONE AL RIFIUTO
        <>
          <GTag GoogleTagID={props.GoogleTagID} />
          <FBPixel FacebookPixedID={props.FBPixel} />
        </>
      )}
    </>
  );
};

export default CookieConsent2AeS;
