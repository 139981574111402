/* eslint-disable jsx-a11y/iframe-has-title */

import Head from 'next/head';
import Script from 'next/script';

declare global {
  interface Window {
    dataLayer: {
      push: (props: { event: string; page_path?: string }) => void;
    };
  }
}

// log the pageview with their URL
export const GooglePageview = (url: string) => {
  // window.gtag('config', GoogleTagID, {
  //   page_path: url
  // })
  window.dataLayer.push({ event: 'Page View', page_path: url });
};

// log specific events happening.
export const GoogleEvent = (event: string) => {
  window.dataLayer.push({ event });
};

export const GTag = (props: { GoogleTagID: string }) => (
  <>
    <Head>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${props.GoogleTagID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </Head>

    <Script
      strategy="afterInteractive"
      id="gtm"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${props.GoogleTagID}');
          `,
      }}
    />
  </>
);

export default { GTag, GooglePageview, GoogleEvent };
